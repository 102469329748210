<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pb-15>
      <v-flex xs8 text-left>
        <span class="mainHeader">CATEGORY</span>
      </v-flex>
      <v-flex xs4>
        <v-layout wrap justify-end>
          <v-flex xs5 text-right class="buttons1">
            <v-btn
              class="buttons1"
              color="#3F053C"
              dark
              
              @click="catadddialogue = true"
            >
              <v-icon @click="catadddialogue = true">mdi-plus</v-icon>
              Category</v-btn
            >
          </v-flex>
          <!-- <v-flex xs3 text-right class="buttons1">
            <v-btn
              class="buttons1"
              small
              dark
              color="#3F053C"
              @click="openAll()"
              >Open All</v-btn
            >
          </v-flex>
          <v-flex xs3 class="buttons1">
            <v-btn
              class="buttons1"
              small
              dark
              color="#3F053C"
              @click="closeAll()"
              >Close All</v-btn
            >
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-6>
        <v-card elevation="0" class="pa-4" color="white">
          <v-layout wrap v-if="categoryList">
            <v-flex xs12 v-if="categoryList.length > 0">
              <v-layout wrap>
                <v-flex xs1 px-1 class="text-left tablefont3"
                  ><b>S.No</b></v-flex
                >
                <v-flex xs2 px-1 class="text-left tablefont3"
                  ><b>Name</b></v-flex
                >
                <v-flex xs3 px-1 class="text-left tablefont3"
                  ><b>Code</b></v-flex
                >
                <v-flex xs3 px-1 class="text-left tablefont3"
                  ><b>Description</b></v-flex
                >
                <v-flex xs2 px-1 class="text-left tablefont3"
                  ><b>Action</b></v-flex
                >
                <v-flex xs1 px-1 class="text-center tablefont3"
                  >
                  <v-icon v-if="ExpandStatus2==false" class="px-1" title="open all"  @click="openAll()">mdi-chevron-down</v-icon>
                  <v-icon v-if="ExpandStatus2==true" class="px-1" title="close all"  @click="closeAll()">mdi-chevron-up</v-icon>
                  </v-flex
                >
              </v-layout>
              <!-- <v-layout wrap>
                <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout> -->
              <v-flex xs12 pt-2>
                <v-divider style="background-color: #eeeeee"></v-divider>
              </v-flex>
              <!-------------------------------level2------------------------>
              <v-layout wrap v-for="(value, i) in categoryList" :key="i">
                <v-flex xs12>
                  <!-- :color="value.isexpand == true ? '#d5bed680':''" -->
                  <v-card elevation="0">
                    <!-- class="pt-3 pb-1" -->
                    <v-layout wrap>
                      <v-flex
                        xs1
                        px-1
                        py-2
                        
                        class="text-left tablefont4"
                      >
                        <span>{{ i + 1 }}</span>
                      </v-flex>
                      <v-flex
                        xs2
                        px-1
                        py-2
                        align-self-center
                        class="text-left tablefont4"
                      >
                        <span v-if="value.name">{{ value.name }}</span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex
                        xs3
                        px-1
                        py-2
                        align-self-center
                        class="text-left tablefont4"
                      >
                        <span v-if="value.code">{{ value.code }}</span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex
                        xs3
                        px-1
                        py-2
                        align-self-center
                        class="text-left tablefont4"
                      >
                        <span v-if="value.description">{{
                          value.description
                        }}</span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex
                        xs2
                        px-1
                        py-2
                        align-self-center
                        class="text-left tablefont4"
                      >
                        <v-icon
                          class="pr-2"
                          style="cursor: pointer"
                          color="#3F053C"
                          @click="(cateditdialoge = true), (curitem1 = value)"
                          >mdi-file-edit-outline</v-icon
                        >
                        <v-icon
                          style="cursor: pointer"
                          color="#3F053C"
                          @click="(catdeldialoge = true), (curId1 = value._id)"
                          >mdi-delete</v-icon
                        >
                        <!-- <v-btn
                              title="Add new item"
                               color="#3F053C"
                              dense outlined small
                              class="body-2 font-weight-bold ml-2"
                              @click="
                                itemadddialogue = true;
                                code9 = value.code;
                                catid = value._id;
                              "
                              > -->
                        <v-icon
                          title="Add new item"
                          color="#3F053C"
                          @click="
                            itemadddialogue = true;
                            code9 = value.code;
                            catid = value._id;
                          "
                        >
                          mdi-plus</v-icon
                        >
                        <!-- Item</v-btn> -->
                      </v-flex>
                      <v-flex
                        xs1
                        align-self-center
                        v-if="value.isexpand == true"
                      >
                        <v-icon @click="value.isexpand = false"
                          >mdi-chevron-up</v-icon
                        >
                      </v-flex>
                      <v-flex
                        xs1
                        align-self-center
                        v-if="value.isexpand == false"
                      >
                        <v-icon @click="value.isexpand = true"
                          >mdi-chevron-down</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 v-if="value.isexpand == true">
                        <!-- <v-layout wrap pt-2>
                          <v-flex xs12 sm6 md10 text-left px-4>
                            <span class="mainHeader2">ITEMS</span>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            md2
                            pb-2
                            pb-sm-0
                            text-left
                            text-sm-right
                          >
                            <v-btn
                              title="Add new item"
                              class="body-2 font-weight-bold"
                              dark color="#3F053C"
                              small
                              
                              @click="
                                itemadddialogue = true;
                                code9 = value.code;
                                catid = value._id;
                              "
                              ><v-icon >mdi-plus</v-icon>Item</v-btn
                            >
                          </v-flex>
                        </v-layout> -->

                        <v-card elevation="0" v-if="value.items">
                          <!-------------------------------level3------------------------>
                          <!-- <v-layout wrap px-4>
                            <v-flex xs1 px-1 class="text-left tablefont3"
                              ><b>S.No</b></v-flex
                            >
                            <v-flex xs3 px-1 class="text-left tablefont"
                              ><b>Name</b></v-flex
                            >
                            <v-flex xs3 px-1 class="text-left tablefont"
                              ><b>Code</b></v-flex
                            >
                            <v-flex xs3 px-1 class="text-left tablefont"
                              ><b>Description</b></v-flex
                            >
                            <v-flex xs2 px-1 class="text-left tablefont"
                              ><b>Action</b></v-flex
                            >
                          </v-layout> -->
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-divider></v-divider>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap v-if="value.items">
                            <v-flex xs12 v-if="value.items.length > 0">
                              <!-- class="pt-3 pb-1" -->
                              <v-card
                                elevation="0"
                                v-for="(value2, j) in value.items"
                                :key="j"
                                :style="
                                  value2.isexpand == true
                                    ? { 'background-color': '#e1cce480' }
                                    : { 'background-color': '#d5bed680' }
                                "
                              >
                                <v-layout wrap>
                                  <v-flex
                                    xs1
                                    align-self-center
                                    py-2
                                    px-1
                                    class="text-center tablefont5"
                                  >
                                    <span>{{ j + 1 }}</span>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    px-1
                                    align-self-center
                                    py-2
                                    class="text-left tablefont5"
                                  >
                                    <span v-if="value2.name">{{
                                      value2.name
                                    }}</span>
                                    <span v-else>-</span>
                                  </v-flex>
                                  <v-flex
                                    xs3
                                    px-1
                                    align-self-center
                                    py-2
                                    class="text-left tablefont5"
                                  >
                                    <span v-if="value2.code">{{
                                      value2.code
                                    }}</span>
                                    <span v-else>-</span>
                                  </v-flex>
                                  <v-flex
                                    xs3
                                    px-1
                                    align-self-center
                                    py-2
                                    class="text-left tablefont5"
                                  >
                                    <span v-if="value2.description">{{
                                      value2.description
                                    }}</span>
                                    <span v-else>-</span>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    px-1
                                    align-self-center
                                    py-2
                                    class="text-left tablefont5"
                                  >
                                    <v-icon
                                      color="#3F053C"
                                      style="cursor: pointer"
                                      @click="
                                        (itemeditdialoge = true),
                                          (curitem2 = value2);
                                        editcode(curitem2.code);
                                      "
                                      >mdi-file-edit-outline</v-icon
                                    >
                                    <v-icon
                                      color="#3F053C"
                                      style="cursor: pointer"
                                      @click="
                                        (itemdeldialoge = true),
                                          (curId2 = value2._id)
                                      "
                                      >mdi-delete</v-icon
                                    >
                                    <!-- <v-btn
               title="Add new design"
                class="body-2 font-weight-bold ml-2"
                outlined small color="#3F053C" dark
                @click="(designadddialogue=true);(code8=value2.code);(catid2=value2._id)"
                > -->
                                    <v-icon
                                      style="color: #3f053c"
                                      @click="
                                        designadddialogue = true;
                                        code8 = value2.code;
                                        catid2 = value2._id;
                                      "
                                      >mdi-plus</v-icon
                                    >
                                    <!-- Design</v-btn
              > -->
                                  </v-flex>
                                  <v-flex
                                    xs1
                                    align-self-center
                                    v-if="value2.isexpand == true"
                                  >
                                    <v-icon @click="value2.isexpand = false"
                                      >mdi-chevron-up</v-icon
                                    >
                                  </v-flex>
                                  <v-flex
                                    xs1
                                    align-self-center
                                    v-if="value2.isexpand == false"
                                  >
                                    <v-icon @click="value2.isexpand = true"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    v-if="value2.isexpand == true"
                                    style="background-color: #e6dee780"
                                  >
                                    <!-- <v-layout wrap>
                          <v-flex xs12 sm6 md10 text-left px-4>
                            <span class="mainHeader2">Design</span>
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            md2
                            pb-2
                            pb-sm-0
                            text-left
                            text-sm-right
                          >
                          <v-btn
               title="Add new design"
                class="body-2 font-weight-bold"
                outlined small text
                @click="(designadddialogue=true);(code8=value2.code);(catid2=value2._id)"
                ><v-icon style=" color:#3F053C">mdi-plus</v-icon>Design</v-btn
              >
                          </v-flex>
                        </v-layout> -->
                                    <v-card
                                      elevation="0"
                                     
                                      v-if="value2.design"
                                      color="#f7f3f7"
                                    >
                                      <!-------------------------------level3------------------------>
                                      <!-- <v-layout wrap px-4>
                                        <v-flex
                                          xs1
                                          px-1
                                          class="text-left tablefont3"
                                          ><b>S.No</b></v-flex
                                        >
                                        <v-flex
                                          xs3
                                          px-1
                                          class="text-left tablefont"
                                          ><b>Name</b></v-flex
                                        >
                                        <v-flex
                                          xs3
                                          px-1
                                          class="text-left tablefont"
                                          ><b>Code</b></v-flex
                                        >
                                        <v-flex
                                          xs3
                                          px-1
                                          class="text-left tablefont"
                                          ><b>Description</b></v-flex
                                        >
                                        <v-flex
                                          xs2
                                          px-1
                                          class="text-left tablefont"
                                          ><b>Action</b></v-flex
                                        >
                                      </v-layout> -->
                                      <v-layout wrap>
                                        <v-flex xs12>
                                          <v-divider></v-divider>
                                        </v-flex>
                                      </v-layout>
                                      <v-layout
                                        wrap
                                        v-for="(value3, k) in value2.design"
                                        :key="k"
                                       
                                      >
                                        <v-flex xs12>
                                          <v-card
                                            elevation="0"
                                            color="transparent"
                                          >
                                            <v-layout wrap>
                                              <v-flex
                                                xs1
                                                px-1 py-2 align-self-center
                                                class="text-center tablefont6"
                                              >
                                                <span>{{ k + 1 }}</span>
                                              </v-flex>
                                              <v-flex
                                                xs2
                                                px-1 py-2 align-self-center
                                                class="text-left tablefont6"
                                              >
                                                <span v-if="value3.name">{{
                                                  value3.name
                                                }}</span>
                                                <span v-else>-</span>
                                                <span class="pl-1"
                                                  v-if="value3.countOrSplit"
                                                  >({{
                                                    value3.countOrSplit
                                                  }})</span
                                                >
                                              </v-flex>
                                              <v-flex
                                                xs3
                                                px-1 py-2 align-self-center
                                                class="text-left tablefont6"
                                              >
                                                <span v-if="value3.code">{{
                                                  value3.code
                                                }}</span>
                                                <span v-else>-</span>
                                              </v-flex>
                                              <v-flex
                                                xs3
                                                px-1 py-2 align-self-center
                                                class="text-left tablefont6"
                                              >
                                                <span
                                                  v-if="value3.description"
                                                  >{{
                                                    value3.description
                                                  }}</span
                                                >
                                                <span v-else>-</span>
                                              </v-flex>
                                              <v-flex
                                                xs2
                                                px-1 py-2 align-self-center
                                                class="text-left"
                                              >
                                                <v-icon
                                                  size="18"
                                                  color="#3F053C"
                                                  class="pr-2"
                                                  style="cursor: pointer"
                                                  @click="
                                                    curitem3 = value3;
                                                    designeditdialoge = true;
                                                    editcode3(curitem3.code);
                                                  "
                                                  >mdi-file-edit-outline</v-icon
                                                >
                                                <v-icon
                                                  size="18"
                                                  color="#3F053C"
                                                  style="cursor: pointer"
                                                  @click="
                                                    designdeldialoge = true;
                                                    curId3 = value3._id;
                                                  "
                                                  >mdi-delete</v-icon
                                                >
                                              </v-flex>
                                              <!-- <v-flex
                                                xs1
                                                px-1 align-self-center
                                                class="text-left tablefont6"
                                              >
                                                <span
                                                  v-if="value3.countOrSplit"
                                                  >{{
                                                    value3.countOrSplit
                                                  }}</span
                                                >
                                                <span v-else>-</span></v-flex
                                              > -->
                                            </v-layout>
                                            <!------------------------------------------------------->
                                          </v-card>
                                        </v-flex>
                                      </v-layout>

                                      <!------------------------------------------------------->
                                    </v-card>
                                  </v-flex>
                                </v-layout>
                                <!------------------------------------------------------->
                              </v-card>
                            </v-flex>
                            <v-flex xs12 v-else class="table">
                              <span>No Items Found</span>
                            </v-flex>
                          </v-layout>
                          <!------------------------------------------------------->
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <!------------------------------------------------------->
                  </v-card>
                </v-flex>
                <v-flex xs12 style="background-color: #eeeeee">
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1">
              No data found
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!---------------------DIALOGUE------------------------->
    <v-dialog width="800px" v-model="catadddialogue">
      <v-form v-model="addcat1" ref="addcat1" @submit.prevent>
        <v-card width="800px" class="pa-2">
          <v-layout wrap justify-center>
            <v-flex xs11 text-center
              ><span style="color: #3f053c" class="menuhead1"
                >Add Category</span
              ></v-flex
            >
            <v-flex xs1 text-right>
              <v-icon
                @click="(catadddialogue = false)(resetForm1())"
                color="#3F053C"
                >mdi-close-box</v-icon
              ></v-flex
            >
            <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 text-left class="subhed">Name</v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    color="#b234a9"
                    placeholder="Name"
                    v-model="catname"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-1 class="subhed" text-left>Code </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="catcode"
                    dense
                    color="#b234a9"
                    placeholder="code"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 class="subhed" text-left
                  >Description
                </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="catdescription"
                    dense
                    outlined
                    type="text"
                    color="#b234a9"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-spacer></v-spacer>
                </v-flex>
                <v-flex xs6 text-right px-4 pt-8>
                  <v-btn
                    color="#3F053C"
                    class="buttons1"
                    dark
                    block
                    @click="AddCategoryValidation()"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="catadddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="buttons1"
            dark
            @click="AddCategoryValidation()"
            >OK</v-btn
          >
        </v-card-actions> -->
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog width="800px" v-model="cateditdialoge">
      <v-card width="800px" class="pa-2">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Edit Category</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="cateditdialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center py-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 py-4>
                <v-flex xs12 text-left py-1>Name</v-flex>
                <v-text-field
                  autofocus
                  color="#b234a9"
                  v-model="curitem1.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs12 px-4 pb-4>
                <v-flex xs12 text-left py-1>Code</v-flex>
                <v-text-field
                  v-model="curitem1.code"
                  dense
                  color="#b234a9"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-flex xs12 text-left py-1>Description</v-flex>
                <v-textarea
                  rows="1"
                  color="#b234a9"
                  autofocus
                  v-model="curitem1.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-layout wrap>
                  <v-flex xs6>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs6 text-right pt-8>
                    <v-btn
                      color="#3F053C"
                      class="buttons1"
                      dark
                      block
                      @click="cateditValidation()"
                      >Save</v-btn
                    ></v-flex
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cateditdialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="cateditValidation()"
            >Save</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="catdeldialoge">
      <v-card width="400px" class="pa-2">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Delete</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="catdeldialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center py-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-4 tablefont"
          >Are you sure you want to delete this category?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="catdeldialoge = false"
            >Cancel</v-btn
          > -->
          <v-btn color="#3F053C" class="buttons1" dark @click="delcategory()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---------------------ITEM------------------------->
    <v-dialog width="800px" v-model="itemadddialogue">
      <v-form v-model="addcat2" ref="addcat2" @submit.prevent>
        <v-card width="800px" class="pa-2">
          <v-layout wrap justify-center>
            <v-flex xs11 text-center
              ><span style="color: #3f053c" class="menuhead1"
                >Add Item</span
              ></v-flex
            >
            <v-flex xs1 text-right>
              <v-icon
                @click="(itemadddialogue = false)(resetForm2())"
                color="#3F053C"
                >mdi-close-box</v-icon
              ></v-flex
            >
            <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 text-left class="subhed">Name</v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    color="#b234a9"
                    placeholder="Name"
                    v-model="itemname"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-1 class="subhed" text-left>Code </v-flex>
                <v-flex xs3 pl-4 pr-1 pb-4 align-self-center text-right
                  >{{ code9 }}/</v-flex
                >
                <v-flex xs9 pr-4 pl-1 pb-4>
                  <v-text-field
                    v-model="itemcode"
                    dense
                    color="#b234a9"
                    placeholder="code"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 class="subhed" text-left
                  >Description
                </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="itemdescription"
                    dense
                    outlined
                    type="text"
                    color="#b234a9"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-spacer></v-spacer>
                </v-flex>
                <v-flex xs6 text-right px-4 pt-8>
                  <v-btn
                    color="#3F053C"
                    class="buttons1"
                    dark
                    block
                    @click="AddItemValidation()"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="itemadddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="AddItemValidation()"
            >OK</v-btn
          >
        </v-card-actions> -->
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog width="800px" v-model="itemeditdialoge">
      <v-card width="800px" class="pa-2">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Edit Item</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="itemeditdialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center py-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 py-4>
                <v-flex xs12 text-left py-1>Name</v-flex>
                <v-text-field
                  autofocus
                  color="#b234a9"
                  v-model="curitem2.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <!-- <v-flex xs12 px-4 pb-4> -->
              <v-flex xs12 text-left py-1 pl-4>Code</v-flex>
              <v-flex xs3 pl-4 pr-1 pb-4 align-self-center text-right
                >{{ code4 }}/</v-flex
              >
              <v-flex xs9 pr-4 pl-1 pb-4>
                <v-text-field
                  v-model="curitem2.code"
                  dense
                  color="#b234a9"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-flex xs12 text-left py-1>Description</v-flex>
                <v-textarea
                  rows="1"
                  color="#b234a9"
                  autofocus
                  v-model="curitem2.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-layout wrap>
                  <v-flex xs6>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs6 text-right pt-8>
                    <v-btn
                      color="#3F053C"
                      class="buttons1"
                      dark
                      block
                      @click="EditItemValidation()"
                      >Save</v-btn
                    ></v-flex
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="itemeditdialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="EditItemValidation()"
            >Save</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="itemdeldialoge">
      <v-card width="400px" class="pa-2">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Delete</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="itemdeldialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center py-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-4 tablefont"
          >Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="itemdeldialoge = false"
            >Cancel</v-btn
          > -->
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="delitem()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---------------------ITEM------------------------->
    <v-dialog width="800px" v-model="designadddialogue">
      <v-form v-model="addcat3" ref="addcat3" @submit.prevent>
        <v-card width="800px" class="pa-2">
          <v-layout wrap justify-center>
            <v-flex xs11 text-center
              ><span style="color: #3f053c" class="menuhead1"
                >Add Design</span
              ></v-flex
            >
            <v-flex xs1 text-right>
              <v-icon
                @click="(designadddialogue = false)(resetForm3())"
                color="#3F053C"
                >mdi-close-box</v-icon
              ></v-flex
            >
            <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 text-left class="subhed">Name</v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    color="#b234a9"
                    placeholder="Name"
                    v-model="designname"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-1 class="subhed" text-left>Code </v-flex>
                <v-flex xs4 pl-4 pr-1 pb-4 text-right align-self-center
                  >{{ code8 }}/</v-flex
                >
                <v-flex xs8 pr-4 pl-1 pb-4>
                  <v-text-field
                    v-model="designcode"
                    dense
                    color="#b234a9"
                    placeholder="code"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-1 class="subhed" text-left>Type </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-select
                    outlined
                    hide-details
                    dense
                    color="#b234a9"
                    v-model="designcountOrSplit"
                    :items="type"
                    label="Select Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 px-2>
              <v-layout wrap>
                <v-flex xs12 px-4 py-1 class="subhed" text-left
                  >Description
                </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="designdescription"
                    dense
                    outlined
                    type="text"
                    color="#b234a9"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 px-4 py-1 class="subhed" text-left>HSN </v-flex>
                <v-flex xs9 px-4 pt-1 text-right>
                  <v-chip
                    small
                    outlined
                    tile
                    color="#3F053C"
                    @click="addHSNdialog = true"
                  >
                    <v-icon small>mdi-plus</v-icon> Add HSN
                  </v-chip>
                </v-flex>
                <v-flex xs12 px-4 pb-4>
                  <v-select
                    :items="HSNList"
                    v-model="designHSN"
                    color="#b234a9"
                    item-text="hsnCode"
                    item-value="_id"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-spacer></v-spacer>
                </v-flex>
                <v-flex xs6 text-right px-4 pt-8>
                  <v-btn
                    color="#3F053C"
                    class="buttons1"
                    dark
                    block
                    @click="AdddesignValidation()"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="designadddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="AdddesignValidation()"
            >OK</v-btn
          >
        </v-card-actions> -->
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog width="800px" v-model="designeditdialoge">
      <v-card width="800px" class="pa-4">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Edit Design</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="designeditdialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center pt-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 py-4>
                <v-flex xs12 text-left py-1>Name</v-flex>
                <v-text-field
                  autofocus
                  color="#b234a9"
                  v-model="curitem3.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <!-- <v-flex xs12 px-4 pb-4> -->
              <v-flex xs12 text-left py-1 pl-4>Code</v-flex>
              <v-flex xs4 pl-4 pr-1 pb-4 align-self-center text-right
                >{{ coded4 }}/</v-flex
              >
              <v-flex xs8 pr-4 pl-1 pb-4>
                <v-text-field
                  v-model="curitem3.code"
                  dense
                  color="#b234a9"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pb-4>
                <v-flex xs12 text-left py-1>Type</v-flex>
                <v-select
                  outlined
                  hide-details
                  dense
                  color="#b234a9"
                  v-model="curitem3.countOrSplit"
                  :items="type"
                  label="Select Type"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 px-2>
            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-flex xs12 text-left py-1>Description</v-flex>
                <v-textarea
                  rows="1"
                  color="#b234a9"
                  autofocus
                  v-model="curitem3.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-flex xs12 text-left py-1>HSN</v-flex>
                <v-select
                  :items="HSNList"
                  v-model="curitem3.HSN"
                  color="#b234a9"
                  item-text="hsnCode"
                  item-value="_id"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-layout wrap>
                  <v-flex xs6>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs6 text-right pt-8>
                    <v-btn
                      color="#3F053C"
                      class="buttons1"
                      dark
                      block
                      @click="editdesignValidation()"
                      >Save</v-btn
                    ></v-flex
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="designeditdialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="editdesignValidation()"
            >Save</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="designdeldialoge">
      <v-card width="400px" class="pa-2">
        <v-layout wrap justify-center>
          <v-flex xs11 text-center
            ><span style="color: #3f053c" class="menuhead1"
              >Delete</span
            ></v-flex
          >
          <v-flex xs1 text-right>
            <v-icon @click="designdeldialoge = false" color="#3F053C"
              >mdi-close-box</v-icon
            ></v-flex
          >
          <v-flex xs8 text-center py-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-4 tablefont"
          >Are you sure you want to delete this design?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="designdeldialoge = false"
            >Cancel</v-btn
          > -->
          <v-btn
            color="#3F053C"
            class="body-2 font-weight-bold"
            dark
            @click="deldesign()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="addHSNdialog">
      <v-form v-model="addcat4" ref="addcat4" @submit.prevent>
        <v-card tile width="400px" class="px-4 py-4 rounded-lg">
          <v-layout wrap justify-center>
            <v-flex xs11 text-center
              ><span style="color: #3f053c" class="menuhead1"
                >Add New HSN</span
              ></v-flex
            >
            <v-flex xs1 text-right>
              <v-icon
                @click="(addHSNdialog = false)(resetForm4())"
                color="#3F053C"
                >mdi-close-box</v-icon
              ></v-flex
            >
            <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap pb-4>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">HSN Code</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        :rules="[rules.required]"
                        type="text"
                        v-model="newHSNcode"
                        outlined
                        dense
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">Name</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        :rules="[rules.required]"
                        type="text"
                        placeholder="Enter HSN name"
                        v-model="newHSNname"
                        outlined
                        dense
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">Slab Percentage</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        :rules="[rules.required]"
                        type="number"
                        v-model="newHSNpercent"
                        outlined
                        dense
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pb-4> -->

                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">Description</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        type="text"
                        v-model="newHSNdescription"
                        outlined
                        dense
                        color="#b234a9"
                        hide-details="auto"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  px-4
                  pt-4
                  text-right
                  align-self-end
                  class="buttons1"
                >
                  <v-btn
                    type="submit"
                    color="#3F053C"
                    dark
                    block
                    @click="AddHsn()"
                    ><v-icon>mdi-plus</v-icon>Add</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NestedDataTable",
  props: {
    msg: String,
  },
  data() {
    return {
      testup: false,
      testup1: false,
      testup2: false,
      addcat1: false,
      addcat2: false,
      addcat3: false,
      addcat4: false,
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg14: null,
      page: 1,
      Pagelength: null,
      jewelleryId: null,
      adminList: [],
      rolesList: [],
      name: null,
      description: null,
      code: null,
      phoneNumber: null,
      positionList: null,
      categoryList: [],
      adddialog: false,
      HSNList: [],
      type: ["Split", "Count"],
      //category
      catadddialogue: false,
      cateditdialoge: false,
      curitem1: [],
      catdeldialoge: false,
      curItemHSN: {
        hsnCode: "",
        name: "",
        hsnDescription: "",
        gstSlab1Percentage: "",
      },
      curId1: "",
      catname: "",
      catcode: "",
      catdescription: "",
      categoryList1: [],
      //item
      itemadddialogue: false,
      itemname: "",
      itemcode: "",
      itemdescription: "",
      catid: "",
      code9: "",
      curitem2: [],
      curId2: "",
      itemeditdialoge: false,
      itemdeldialoge: false,
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      //design
      designadddialogue: false,
      designname: "",
      designcode: "",
      designdescription: "",
      designHSN: "",
      catid2: "",
      code8: "",
      curitem3: [],
      curId3: "",
      designcountOrSplit: "",
      designeditdialoge: false,
      designdeldialoge: false,
      coded1: null,
      coded2: null,
      coded3: null,
      coded4: null,
      //HSN
      addHSNdialog: false,
      newHSNpercent: null,
      newHSNcode: null,
      newHSNname: null,
      newHSNdescription: null,
      //
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      // exp1:false,
      // exp2:false,
      ExpandStatus2:false,
    };
  },
  mounted() {
    this.getCategory1();
    this.getHSN();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },

  methods: {
    resetForm1() {
      this.$refs.addcat1.reset();
    },
    resetForm2() {
      this.$refs.addcat2.reset();
    },
    resetForm3() {
      this.$refs.addcat3.reset();
    },
    resetForm4() {
      this.$refs.addcat4.reset();
    },
    openAll() {
      this.ExpandStatus2=true;
      this.setExpandStatus(this.categoryList, true);
    },
    closeAll() {
      this.ExpandStatus2=false;
      this.setExpandStatus(this.categoryList, false);
    },
    setExpandStatus(list, status) {
      for (const item of list) {
        item.isexpand = status;
        if (item.items) {
          this.setExpandStatus(item.items, status);
        }
        if (item.design) {
          for (const subItem of item.design) {
            subItem.isexpand = status;
          }
        }
      }
    },

    //
    preventDefault(event) {
      event.preventDefault();
    },
    getCategory1() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "all/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //CATEGORY
    AddCategoryValidation() {
      if (!this.catname) {
        this.msg = "Please enter category name";
        this.showsnackbar = true;
      } else if (!this.catcode) {
        this.msg = "Please enter category code";
        this.showsnackbar = true;
      } else {
        this.AddCategory();
      }
    },
    cateditValidation() {
      if (!this.curitem1.name) {
        this.msg = "Please enter category name";
        this.showsnackbar = true;
      } else if (!this.curitem1.code) {
        this.msg = "Please enter category code";
        this.showsnackbar = true;
      } else {
        this.editcat();
      }
    },
    AddCategory() {
      axios({
        url: "/category/add",
        method: "POST",
        data: {
          name: this.catname,
          description: this.catdescription,
          code: this.catcode,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.catadddialogue = false;
            this.catname = null;
            this.catdescription = null;
            this.catcode = null;
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat() {
      axios({
        url: "/category/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curitem1._id,
          name: this.curitem1.name,
          code: this.curitem1.code,
          description: this.curitem1.description,
        },
      })
        .then((response) => {
          this.cateditdialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curitem1 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCategory1();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    delcategory() {
      axios({
        url: "/category/delete",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId1,
        },
      })
        .then((response) => {
          this.catdeldialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curId1 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //ITEM
    AddItemValidation() {
      if (!this.itemname) {
        this.msg = "Please enter item name";
        this.showsnackbar = true;
      } else if (!this.itemcode) {
        this.msg = "Please enter item code";
        this.showsnackbar = true;
      } else {
        this.AddItem();
      }
    },
    EditItemValidation() {
      if (!this.curitem2.name) {
        this.msg = "Please enter item name";
        this.showsnackbar = true;
      } else if (!this.curitem2.code) {
        this.msg = "Please enter item code";
        this.showsnackbar = true;
      } else {
        this.editcode2();
      }
    },
    AddItem() {
      console.log("catid==", this.catid);
      var newcode = this.code9 + "/" + this.itemcode;
      axios({
        url: "/item/add",
        method: "POST",
        data: {
          name: this.itemname,
          categoryId: this.catid,
          code: newcode,
          description: this.itemdescription,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.itemadddialogue = false;
            this.itemname = null;
            this.itemcode = null;
            this.itemdescription = null;
            this.newcode = null;
            // this.catid  = null;
            this.code9 = null;
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcode(item) {
      this.code1 = item;
      console.log("code: ", this.code1);
      this.code2 = this.code1.split("/");
      console.log("this.code2: ", this.code2);
      this.code3 = this.code2.pop();
      this.curitem2.code = this.code3;
      console.log("this.code3: ", this.code3);
      console.log("this.code2 new: ", this.code2);
      this.code4 = this.code2.join("/");
      console.log("this.code4: ", this.code4);
    },
    editcode2() {
      this.code3 = this.curitem2.code;
      console.log("this.code3 edited: ", this.code3);
      // this.code2.push(this.code3);
      this.codeNew = this.code4 + "/" + this.code3;
      console.log("this.code2 new new: ", this.codeNew);

      // this.codeNew = this.code2.join("/");
      // console.log("code new: ", this.codeNew);
      this.edititem();
    },
    edititem() {
      axios({
        url: "/item/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curitem2._id,
          name: this.curitem2.name,
          code: this.codeNew,
          description: this.curitem2.description,
        },
      })
        .then((response) => {
          this.itemeditdialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curitem2 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCategory1();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    delitem() {
      axios({
        url: "/item/delete",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId2,
        },
      })
        .then((response) => {
          this.itemdeldialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curId2 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //DESIGN
    AdddesignValidation() {
      if (!this.designname) {
        this.msg = "Please enter design name";
        this.showsnackbar = true;
      } else if (!this.designcode) {
        this.msg = "Please enter design code";
        this.showsnackbar = true;
      } else if (!this.designcountOrSplit) {
        this.msg = "Please enter design type";
        this.showsnackbar = true;
      } else if (!this.designHSN) {
        this.msg = "Please choose HSN code";
        this.showsnackbar = true;
      } else {
        this.Adddesign();
      }
    },
    editdesignValidation() {
      if (!this.curitem3.name) {
        this.msg = "Please enter design name";
        this.showsnackbar = true;
      } else if (!this.curitem3.code) {
        this.msg = "Please enter design code";
        this.showsnackbar = true;
      } else if (!this.curitem3.countOrSplit) {
        this.msg = "Please enter design type";
        this.showsnackbar = true;
      } else if (!this.curitem3.HSN) {
        this.msg = "Please choose HSN code";
        this.showsnackbar = true;
      } else {
        this.editcode4();
      }
    },
    Adddesign() {
      console.log("catid==", this.catid);
      var newcode = this.code8 + "/" + this.designcode;
      axios({
        url: "/design/add",
        method: "POST",
        data: {
          name: this.designname,
          itemId: this.catid2,
          code: newcode,
          description: this.designdescription,
          HSN: this.designHSN,
          countOrSplit: this.designcountOrSplit,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.designadddialogue = false;
            this.designname = null;
            this.designcode = null;
            this.designdescription = null;
            this.newcode = null;
            // this.catid  = null;
            this.code9 = null;
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcode3(item) {
      this.coded1 = item;
      console.log("code: ", this.coded1);
      this.coded2 = this.coded1.split("/");
      console.log("this.coded2: ", this.coded2);
      this.coded3 = this.coded2.pop();
      this.curitem3.code = this.coded3;
      console.log("this.coded3: ", this.coded3);
      console.log("this.coded2 new: ", this.coded2);
      this.coded4 = this.coded2.join("/");
      console.log("this.coded4: ", this.coded4);
    },
    editcode4() {
      this.coded3 = this.curitem3.code;
      console.log("this.coded3 edited: ", this.coded3);
      // this.coded2.push(this.coded3);
      this.codedNew = this.coded4 + "/" + this.coded3;
      console.log("this.coded2 new new: ", this.codedNew);

      // this.codedNew = this.coded2.join("/");
      // console.log("code new: ", this.codedNew);
      this.editdesign();
    },
    editdesign() {
      axios({
        url: "/design/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curitem3._id,
          name: this.curitem3.name,
          code: this.codedNew,
          description: this.curitem3.description,
          HSN: this.curitem3.HSN,
          countOrSplit: this.curitem3.countOrSplit,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.designeditdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curitem3 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCategory1();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deldesign() {
      axios({
        url: "/design/delete",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId3,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.designdeldialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.curId3 = "";
            this.getCategory1();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //HSN
    getHSN() {
      // this.appLoading = true;
      axios({
        url: "/hsnCodes/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 50,
          searchKeyword: "",
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.HSNList = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / 50);
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    AddHsn() {
      // this.appLoading = true;
      axios({
        url: "/hsncode/add",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.newHSNname,
          hsnCode: this.newHSNcode,
          hsnDescription: this.newHSNdescription,
          gstSlab1Percentage: this.newHSNpercent,
        },
      })
        .then((response) => {
          this.showSnackBar = true;
          this.addHSNdialog = false;
          this.msg = response.data.msg;
          this.newHSNpercent = null;
          this.newHSNcode = null;
          this.newHSNname = null;
          this.newHSNdescription = null;
          this.$refs.addcat4.reset();
          // this.appLoading = false;
          this.getHSN();
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
